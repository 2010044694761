import BaseClass from "@/utils/baseclass";

export default class ApprovalTransferOperations extends BaseClass {
  addItem(item) {
    let newItem = new TransferApproval(item);
    this.list.push(newItem);
  }
}

export class TransferApproval {
  constructor(item) {
    this.id = item.id;
    this.code = item.code;
    this.description = item.description;
    this.rejectionReason = item.rejectionReason;
    this.requestDetailFromStoreId = item.requestDetailFromStoreId;
    this.requestDetailStoreId = item.requestDetailStoreId;
    this.requestItemOrderStatus = item.requestItemOrderStatus;
    this.status = item.status;
    this.type = item.type;
    this.totalAmount = item.totalAmount;
    this.requestDetailFromStoreName = item.requestDetailFromStoreName;
    this.requestDetailStoreName = item.requestDetailStoreName;
    this.detailList = [];
    this.storeId = item.storeId;
    this.storeName = item.storeName;
    this.headerType = item.headerType;
    this.approvalStatus = item.approvalStatus;
    this.maxApprovalLevel = item.maxApprovalLevel;
    this.fromStoreName = item.fromStoreName;
    this.fromStoreId = item.fromStoreId;
    this.currentApprovalLevel = item.currentApprovalLevel;
    this.transferDate = item.transferDate;
    this.showDetail = false;
    this.authorizedUserLevel = item.authorizedUserLevel;
    this.info = item.info;
  }

  addDetails(detailList) {
    this.detailList = [];
    detailList.forEach((detail) => {
      this.detailList.push(new TransferDetailApproval(detail));
    });
  }
}

export class TransferDetailApproval {
  constructor(detail) {
    this.baseUnitPrice = detail.baseUnitPrice;
    this.id = detail.id;
    this.headerId = detail.headerId;
    this.description = detail.description;
    this.fromStoreId = detail.fromStoreId;
    this.fromStoreName = detail.fromStoreName;
    this.orderStatus = detail.orderStatus;
    this.quantity = detail.quantity;
    this.requestDate = detail.requestDate;
    this.status = detail.status;
    this.statusReason = detail.statusReason;
    this.stockItemId = detail.stockItemId;
    this.stockItemName = detail.stockItemName;
    this.storeId = detail.storeId;
    this.storeName = detail.storeName;
    this.taxId = detail.taxId;
    this.totalAmount = detail.totalAmount;
    this.unitId = detail.unitId;
    this.unitName = detail.unitName;
    this.name = detail.name;
    this.unitCode = detail.unitCode;
    this.unitPrice = detail.unitPrice;
    this.headerType = detail.headerType;
    this.supplierName = detail.supplierName;
  }
}
