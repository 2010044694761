import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import ApprovalTransferOperations from '../model'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    TransferApprovalList: new ApprovalTransferOperations(),
    TransferApprovalDetailList: [],
    PageLinks: [
      'ApproveOperationsTransfer',
      'ApproveOperationsOrder',
      'ApproveOperationsApproveOrder',
      'ApproveOperationsApproveTransfer'
    ],
    TransferPage: {
      number: 1,
      size: 20,
      total: 0
    }
  },
  getters: {
    PageLinks: state => state.PageLinks,
    isFetchDataEmpty: state => state.TransferApprovalList.list.length === 0,
    TransferApprovalList: state => state.TransferApprovalList.list,
    TransferPage: state => ({
      ...state.TransferPage,
      last: Math.ceil(state.TransferPage.total / state.TransferPage.size)
    })
  },
  actions: {
    async getAllApprovalTransfer({ commit }, payload) {
      const results = await requestHandler({
        title: 'getAllApprovalTransfer',
        methodName: 'getAllApprovalTransfer',
        method: () => {
          return HTTP.post('Request/transfer/approval', payload)
        },
        success: result => {
          commit('SET_TRANSFER_APPROVAL_HEADER', result)
          return result
        }
      })
      return results
    },
    async approveTransfer({ commit }, payload) {
      const results = await requestHandler({
        title: 'approveTransfer',
        methodName: 'approveTransfer',
        method: () => {
          return HTTP.put('Request/transfer/approve', payload)
        },
        success: result => result
      })
      return results
    },
    async rejectTransfer({ commit }, payload) {
      const results = await requestHandler({
        title: 'rejectTransfer',
        methodName: 'rejectTransfer',
        method: () => {
          return HTTP.put('Request/transfer/reject', payload)
        },
        success: result => result
      })
      return results
    },
    async getAllApprovalOrder({ commit }, payload) {
      const results = await requestHandler({
        title: 'getAllApprovalOrder',
        methodName: 'getAllApprovalOrder',
        method: () => {
          return HTTP.post('Request/order/approval', payload)
        },
        success: result => {
          commit('SET_TRANSFER_APPROVAL_HEADER', result)
          return result
        }
      })
      return results
    },

    async getApprovalOrderList({ commit, state }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.TransferPage.number })
      const results = await requestHandler({
        title: 'getApprovalOrderList',
        methodName: 'getApprovalOrderList',
        method: () => {
          return HTTP.post('Approval/order/header', {
            ...payload,
            PageSize: payload.pageSize || state.TransferPage.size,
            pageNumber: PageNumber
          })
        },
        success: result => {
          commit('SET_APPROVE_ORDER_HEADER', result)
          return result
        }
      })
      return results
    },

    async getApprovalTransferList({ commit, state }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.TransferPage.number })
      const results = await requestHandler({
        title: 'getApprovalTransferList',
        methodName: 'getApprovalTransferList',
        method: () => {
          return HTTP.post('Approval/transfer/header', {
            ...payload,
            PageSize: payload.pageSize || state.TransferPage.size,
            pageNumber: PageNumber
          })
        },
        success: result => {
          commit('SET_APPROVE_TRANSFER_HEADER', result)
          return result
        }
      })
      return results
    },

    async getApprovalOrderDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'getApprovalOrderDetail',
        methodName: 'getApprovalOrderDetail',
        method: () => {
          return HTTP.post('Approval/order/detail/getbyheaderid', payload)
        },
        success: result => result
      })
      return results
    },

    async getApprovalTransferDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'getApprovalTransferDetail',
        methodName: 'getApprovalTransferDetail',
        method: () => {
          return HTTP.post('Approval/transfer/detail/getbyheaderid', payload)
        },
        success: result => result
      })
      return results
    },

    async approveOrder({ commit }, payload) {
      const results = await requestHandler({
        title: 'approveOrder',
        methodName: 'approveOrder',
        method: () => {
          return HTTP.put('Request/order/approve', payload)
        },
        success: result => result
      })
      return results
    },
    async rejectOrder({ commit }, payload) {
      const results = await requestHandler({
        title: 'rejectOrder',
        methodName: 'rejectOrder',
        method: () => {
          return HTTP.put('Request/order/reject', payload)
        },
        success: result => result
      })
      return results
    },
    async approveSelectedOrderHeader({ commit }, approveOrderHeaderList) {
      const results = await requestHandler({
        title: 'approveSelectedOrderHeader',
        methodName: 'approveSelectedOrderHeader',
        method: () => {
          return HTTP.put('Approval/order/header/approve', {approveOrderHeaderList})
        },
        success: result => result
      })
      return results
    },
    async approveSelectedTransferHeader({ commit }, transferHeaderIds) {
      const results = await requestHandler({
        title: 'approveSelectedTransferHeader',
        methodName: 'approveSelectedTransferHeader',
        method: () => {
          return HTTP.put('Approval/transfer/header/approve', { transferHeaderIds })
        },
        success: result => result
      })
      return results
    },
    async rejectSelectedOrderHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'rejectSelectedOrderHeader',
        methodName: 'rejectSelectedOrderHeader',
        method: () => {
          return HTTP.put('Approval/order/header/reject', payload)
        },
        success: result => result
      })
      return results
    },
    async rejectSelectedTransferHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'rejectSelectedTransferHeader',
        methodName: 'rejectSelectedTransferHeader',
        method: () => {
          return HTTP.put('Approval/transfer/header/reject', payload)
        },
        success: result => result
      })
      return results
    },
    async getApproveRequestDetail({ commit }, requestHeaderId) {
      const results = await requestHandler({
        title: 'getApproveRequestDetail',
        methodName: 'getApproveRequestDetail',
        method: () => {
          return HTTP.post('Request/detail/approval/getbyheaderid', { requestHeaderId })
        },
        success: result => result
      })
      return results
    },
    async showHistory({ commit }, payload) {
      const results = await requestHandler({
        title: 'showHistory',
        methodName: 'showHistory',
        method: () => {
          return HTTP.post('Approval/history', payload)
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_TRANSFER_APPROVAL_HEADER(state, result) {
      state.TransferApprovalList.addItems(result.data.requestHeaderList)
    },
    SET_APPROVE_ORDER_HEADER(state, result) {
      state.TransferApprovalList.addItems(result.data.approvalOrderHeaderList)
      state.TransferPage.number = result.data.pageNumber
      state.TransferPage.size = result.data.pageSize
      state.TransferPage.total = result.data.totalCount
    },
    SET_APPROVE_TRANSFER_HEADER(state, result) {
      state.TransferApprovalList.addItems(result.data.approvalTransferHeaderList)
      state.TransferPage.number = result.data.pageNumber
      state.TransferPage.size = result.data.pageSize
      state.TransferPage.total = result.data.totalCount
    },
    HIDE_DETAIL(state, transferApprovalHeader) {
      transferApprovalHeader.showDetail = false
    },
    SHOW_DETAIL(state, { transferApprovalHeaderId, data }) {
      let transferApprovalHeader = state.TransferApprovalList.findItem(transferApprovalHeaderId)
      if (transferApprovalHeader) {
        const detailList = data.requestDetailList.map(item => {
          return {
            headerId: transferApprovalHeaderId,
            ...item
          }
        })
        transferApprovalHeader.addDetails(detailList)
        transferApprovalHeader.showDetail = true
      }
    },
    SHOW_APPROVE_ORDER_DETAIL(state, { orderHeaderId, headerType, data }) {
      let transferApprovalHeader = state.TransferApprovalList.findItem(orderHeaderId)
      if (transferApprovalHeader) {
        const detailList = data.orderDetailList.map(item => {
          return {
            orderHeaderId: orderHeaderId,
            headerType: headerType,
            ...item
          }
        })
        transferApprovalHeader.addDetails(detailList)
        transferApprovalHeader.showDetail = true
      }
    },
    SHOW_APPROVE_TRANSFER_DETAIL(state, { transferHeaderId, data }) {
      let transferApprovalHeader = state.TransferApprovalList.findItem(transferHeaderId)
      if (transferApprovalHeader) {
        const detailList = data.transferDetailList.map(item => {
          return {
            transferHeaderId: transferHeaderId,
            ...item
          }
        })
        transferApprovalHeader.addDetails(detailList)
        transferApprovalHeader.showDetail = true
      }
    }
  }
}
