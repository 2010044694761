import ApproveOperationsOrderIndex from '../index'

export default [
  {
    name: 'ApproveOperationsTransfer',
    component: ApproveOperationsOrderIndex,
    path: '/approve-operations',
    meta: {
      slug: 'approve-operations',
      category: 'Buy',
      main_menu: true,
      page: 'approveOperations',
      tabPermissionKey: 'ApprovalRequestTransfer'
    }
  },
  {
    name: 'ApproveOperationsOrder',
    path: '/approve-operations/order',
    component: ApproveOperationsOrderIndex,
    meta: {
      main_menu: false,
      page: 'approveOperations',
      tabPermissionKey: 'ApprovalRequestOrder'
    }
  },
  {
    name: 'ApproveOperationsApproveOrder',
    path: '/approve-operations/approve-order',
    component: ApproveOperationsOrderIndex,
    meta: {
      main_menu: false,
      page: 'approveOperations',
      tabPermissionKey: 'OrderApproval'
    }
  },
  {
    name: 'ApproveOperationsApproveTransfer',
    path: '/approve-operations/approve-transfer',
    component: ApproveOperationsOrderIndex,
    meta: {
      main_menu: false,
      page: 'approveOperations',
      tabPermissionKey: 'TransferApproval'
    }
  }
]
