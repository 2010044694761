export function ApprovalHeaderFields(
  $t,
  isOrder = false,
  isApproveOrder = false,
  isApproveTransfer = false
) {
  return [
    {
      name: "headerCheckbox",
      type: "portal",
      is_visible: !isApproveOrder && !isApproveTransfer ? true : false,
      get short_title() {
        return "";
      },
      get title() {
        return "";
      },
    },
    {
      name: "fromStoreName",
      is_visible: isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.fromStoreName");
      },
      get title() {
        return $t("ApproveOperations.fromStoreName");
      },
    },
    {
      name: "storeName",
      type: "supplier",
      is_visible: isApproveTransfer || isApproveOrder,
      get short_title() {
        return isApproveOrder
          ? $t("ApproveOperations.storeName")
          : $t("ApproveOperations.toStore");
      },
      get title() {
        return isApproveOrder
          ? $t("ApproveOperations.storeName")
          : $t("ApproveOperations.toStore");
      },
    },
    {
      name: "transferDate",
      type: "date",
      is_visible: isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.detailTableDate");
      },
      get title() {
        return $t("ApproveOperations.detailTableDate");
      },
    },
    {
      name: "requestDetailFromStoreName",
      is_visible: !isOrder && !isApproveOrder && isApproveTransfer,
      get short_title() {
        return !isOrder
          ? $t("ApproveOperations.fromStoreName")
          : $t("ApproveOperations.header_storeName");
      },
      get title() {
        return !isOrder
          ? $t("ApproveOperations.fromStoreName")
          : $t("ApproveOperations.header_storeName");
      },
    },
    {
      name: "requestDetailStoreName",
      is_visible: !isApproveOrder && !isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.storeName");
      },
      get title() {
        return $t("ApproveOperations.storeName");
      },
    },
    {
      name: "code",
      is_visible: true,
      get short_title() {
        return $t("ApproveOperations.code");
      },
      get title() {
        return $t("ApproveOperations.code");
      },
    },
    {
      name: "maxApprovalLevel",
      type: "portal",
      is_visible: isApproveOrder || isApproveTransfer,
      get short_title() {
        return $t("Global.approvalLevel");
      },
      get title() {
        return $t("Global.approvalLevel");
      },
    },
    {
      name: "totalAmount",
      type: "currency",
      is_visible: true,
      get short_title() {
        return $t("ApproveOperations.header_totalAmount");
      },
      get title() {
        return $t("ApproveOperations.header_totalAmount");
      },
    },
    {
      name: "description",
      type: "portal",
      is_visible: true,
      get short_title() {
        return "";
      },
      get title() {
        return "";
      },
    },
    {
      name: "info",
      type: "portal",
      is_visible: true,
      get short_title() {
        return "";
      },
      get title() {
        return "";
      },
    },
  ];
}

export function ApprovalDetailFields(
  $t,
  isOrder = false,
  isApproveOrder = false,
  isApproveTransfer = false
) {
  return [
    {
      name: "fromStoreName",
      is_visible: !isOrder && !isApproveOrder && !isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.fromStoreName");
      },
      get title() {
        return $t("ApproveOperations.fromStoreName");
      },
    },
    {
      name: "supplierName",
      type: "supplier",
      is_visible: !isApproveTransfer,
      get short_title() {
        return $t("Dictionary.Supplier");
      },
      get title() {
        return $t("Dictionary.Supplier");
      },
    },
    {
      name: "requestDate",
      type: "date",
      is_visible: !isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.detailTableDate");
      },
      get title() {
        return $t("ApproveOperations.detailTableDate");
      },
    },
    {
      name: "name",
      is_visible: isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.detailTableStockItem");
      },
      get title() {
        return $t("ApproveOperations.detailTableStockItem");
      },
    },
    {
      name: "stockItemName",
      is_visible: !isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.detailTableStockItem");
      },
      get title() {
        return $t("ApproveOperations.detailTableStockItem");
      },
    },
    {
      name: "unitCode",
      is_visible: isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.detailTableUnit");
      },
      get title() {
        return $t("ApproveOperations.detailTableUnit");
      },
    },
    {
      type: "quantity",
      name: "quantity",
      is_visible: true,
      get short_title() {
        return $t("ApproveOperations.detailTableQuantity");
      },
      get title() {
        return $t("ApproveOperations.detailTableQuantity");
      },
    },
    {
      name: "unitName",
      is_visible: !isApproveTransfer,
      get short_title() {
        return $t("ApproveOperations.detailTableUnit");
      },
      get title() {
        return $t("ApproveOperations.detailTableUnit");
      },
    },
    {
      name: "unitPrice",
      type: "currency",
      is_visible: true,
      get short_title() {
        return $t("ApproveOperations.unitPrice");
      },
      get title() {
        return $t("ApproveOperations.unitPrice");
      },
    },
    {
      name: "baseUnitPrice",
      type: "currency",
      is_visible: true,
      get short_title() {
        return $t("ApproveOperations.baseUnitPrice");
      },
      get title() {
        return $t("ApproveOperations.baseUnitPrice");
      },
    },
    {
      name: "totalAmount",
      type: "currency",
      is_visible: true,
      get short_title() {
        return $t("ApproveOperations.detail_totalAmount");
      },
      get title() {
        return $t("ApproveOperations.detail_totalAmount");
      },
    },
    {
      name: "description",
      type: "portal",
      is_visible: true,
      get short_title() {
        return "";
      },
      get title() {
        return "";
      },
    },
  ];
}
